import { conversationActions } from '../reducers/conversation';
import { chatActions } from '../reducers/chat';
import { startConversation, sendMessage } from '../../connectors/web/connector';
import { TextMsg } from '../../components/chatElements/chatClasses';

export const newConversation = ({ lng, isSignedIn, userId, userEmail }) => {
  return async (dispatch) => {
    try {
      const { messages, inputDisabled } = await startConversation({ lng, isSignedIn, userId, userEmail });
      dispatch(conversationActions.add(messages));
      dispatch(chatActions.setInputDisabled(inputDisabled));
    } catch (error) {
      console.log(error);
    }
  };
};

export const sendUserMessage = ({ text, options }) => {
  console.log('options: ', options);
  return async (dispatch) => {
    const newMsg = new TextMsg({ fromUser: true, text: text });
    dispatch(conversationActions.add([newMsg]));
    try {
      const { messages, inputDisabled } = await sendMessage({ text, options });
      dispatch(conversationActions.add(messages));
      dispatch(chatActions.setInputDisabled(inputDisabled));
    } catch (error) {
      console.log(error);
    }
  };
};