import { TextMsg } from '../components/chatElements/chatClasses';

const processResponse = (rowData) => {
  console.log('rowData: ', rowData);
  const context = rowData.data.context;
  console.log('context: ', context);
  const sessionCode = rowData.data.session_id;
  const dataAnswer = rowData.data.text;

  const messages = [];
  let inputDisabled = false;
  const text = dataAnswer;

  const newMsg = new TextMsg({ fromUser: false, text: text });
  messages.push(newMsg);

  return { messages, sessionCode, context, inputDisabled };
}

export default processResponse;