import React from 'react';
import { useTranslation } from 'react-i18next';

//import { ReactComponent as CloseIcon } from '../../../assets/images/close-bubble-icon.svg';
import closeIcon from '../../../assets/images/close-circle-sharp.png';

import classes from './Bubble.module.css';


const Bubble = (props) =>{
  const { t } = useTranslation('global');
  return (

    <React.Fragment>
      <div className={classes.Bubble} onClick={props.click}>
        <span><p>{t('widget.bubble.text')}</p></span>
        <button 
          className= {classes.CloseButton} 
          onClick={props.close}>
          { /* <CloseIcon/> */ }
          <img src={closeIcon}/>
        </button>
        <span className={classes.Tooltip}></span>
      </div>
    </React.Fragment>

  );
}
export default Bubble;
