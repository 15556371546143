import { createSlice } from '@reduxjs/toolkit';
// import demoInput from '../../demoInput';

const initialState = {
  messages: []
}

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    add(state, action) {
      state.messages = [...state.messages, ...action.payload];
    }
  },
});

export const conversationActions = conversationSlice.actions;

export default conversationSlice.reducer;