import React, { useState } from 'react';
//import widgetImage from '../../assets/images/widget-img.svg';
//import widgetImage from '../../assets/images/mju.jpg';
//import widgetIcon from '../../assets/images/mju-vector.png';
import widgetIconNew from '../../assets/images/message-chat-square.png';
import classes from './WidgetButton.module.css';
import Bubble from './Bubble/Bubble';

const WidgetButton = (props) => {


  const [showBubble, setShowBubble] = useState(true);

  const hideBubble = () => {
    setShowBubble(false);
  }

  const buttonClicked = () => {
    setShowBubble(true);
    props.click();
  }

  let bubble = '';

  if (showBubble) {
    bubble = (
      <Bubble close={hideBubble} />
    )
  }


  return (
    <div className={classes.Widget}>
      {bubble}
      <div className={classes.WidgetButton} onClick={buttonClicked}>
        {/* <img src={widgetImage} alt='Open ChatBot' /> */}
        <img src={widgetIconNew} alt='Open ChatBot' />
      </div>
    </div>
  )

}


export default WidgetButton;