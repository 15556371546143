import React, { useEffect, useRef } from 'react';
import classes from './ChatBody.module.css';
import Spinner from './Spinner/Spinner';

import TextMessage from '../../chatElements/TextMessage/TextMessage';

const ChatBody = (props) => {
  const chatBodyDiv = useRef(null);

  useEffect(() => {
    chatBodyDiv.current.scrollTop = chatBodyDiv.current.scrollHeight - chatBodyDiv.current.clientHeight;
  });

  const processMsgs = (messages) => (
    messages.map((msg, index) => {

      const origin = msg.fromUser ? 'User' : 'Bot';

      switch (msg.type) {
        case 'text':
          return <TextMessage key={index} origin={origin} payload={msg.payload} />
        default:
          return <TextMessage key={index} origin='Bot' payload={{ text: 'Ha ocurrido un error' }} />;
      }

    })
  )

  const msgElements = processMsgs(props.messages);

  return (

    <div className={classes.ChatBody} ref={chatBodyDiv}>
      <div className={classes.MessagesContainer}>
        {msgElements}
        {props.showSpinner ? <Spinner /> : ''}
      </div>
    </div>

  );
}
export default ChatBody;