
import { useSelector, useDispatch } from 'react-redux';
import WidgetButton from './components/WidgetButton/WidgetButton';
import ChatContainer from './components/ChatContainer/ChatContainer';
import { openChat, closeChat } from './store/actions/chat';

const App = ({ lng, isSignedIn, userId, userEmail }) => {

  const dispatch = useDispatch();
  const chatIsOpen = useSelector(state => state.chat.isOpen);

  return (
    <>
      {
        chatIsOpen ?
          <ChatContainer
            lng={lng}
            isSignedIn={isSignedIn}
            userId={userId}
            userEmail={userEmail}
            closeChat={() => dispatch(closeChat())} />
          :
          <WidgetButton click={() => dispatch(openChat())} />
      }
    </>
  );
}

export default App;
