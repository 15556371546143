import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './ChatHeader.module.css';
//import { ReactComponent as WidgetIcon } from '../../../assets/images/widget-icon.svg';
//import widgetIcon from '../../../assets/images/mju-vector.png';
//import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import closeIcon from '../../../assets/images/close-circle-sharp.png';
//import sedjudeLogo from '../../../assets/images/sedjude_logo.png';
import widgetIconNew from '../../../assets/images/message-chat-square.png';

const ChatHeader = (props) =>{
  const { t } = useTranslation('global');
  return (

    <div className={classes.ChatHeader}>
      <div className={classes.HeaderLogo}>
        <div className={classes.Logo}>
          <img src={widgetIconNew}/>
        </div>
        { /* <div className={classes.Title}>
          {t('chatheader.title')}
        </div> */ }
      </div>
      <div className={classes.MoveClose}>
        <div className={classes.CloseButton} onClick={props.close}>
          <img src={closeIcon}/>
          {/* <CloseIcon/> */}
        </div>
      </div>
    </div>

  );
}
export default ChatHeader;