import { chatActions } from '../reducers/chat';

export const openChat = () => {
  return async (dispatch) => {
    // Here you can write some asynchronous code
    dispatch(chatActions.set(true));
  };
};

export const closeChat = () => {
  return async (dispatch) => {
    // Here you can write some asynchronous code
    dispatch(chatActions.set(false));
  };
};