import { configureStore } from '@reduxjs/toolkit';

import chat from './reducers/chat';
import conversation from './reducers/conversation';


const store = configureStore({
  reducer: { 
    chat,
    conversation
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;