
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classes from './ChatContainer.module.css';
import ChatHeader from './ChatHeader/ChatHeader';
import ChatBody from './ChatBody/ChatBody';
import ChatFooter from './ChatFooter/ChatFooter';

import { newConversation, sendUserMessage } from '../../store/actions/conversation';

const ChatContainer = ({ lng, isSignedIn, userId, userEmail, closeChat }) => {

  const [showSpinner, setShowSpinner] = useState(false);

  const dispatch = useDispatch();
  const messagesArray = useSelector(state => state.conversation.messages);

  useEffect(() => {
    // If messages array is empty, It sends the initial message to the broker
    if (!messagesArray.length) {
      initConversation()
    }
    // eslint-disable-next-line 
  }, []);


  const initConversation = async () => {
    setShowSpinner(true);
    await dispatch(newConversation({ lng, isSignedIn, userId, userEmail }));
    setShowSpinner(false);
  }


  // Sending the new user message to the broker
  const addUserMessage = async (text, options) => {
    console.debug('text: ', text);
    console.debug('options: ', options);
    if (!text) return;
    setShowSpinner(true);
    await dispatch(sendUserMessage({ text, options }));
    setShowSpinner(false);
  }

  return (
    <div className={classes.ChatContainer}>
      <ChatHeader close={closeChat} />
      <ChatBody
        messages={messagesArray}
        showSpinner={showSpinner}
        sendMsg={addUserMessage} />
      <ChatFooter send={addUserMessage} />
    </div>
  );

}



export default ChatContainer;