module.exports = {
  conversation: {
    url: {
      instance: 'https://backend.canttdata.com',
      endpoint: 'llm/langchain/getresponse'
    },
    headers: {
      "Content-Type": 'application/json'
    }
  }
}
