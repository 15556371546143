import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Cookies from 'js-cookie';

//internationalization
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_es from './locales/es/global.json';


//Redux
import { Provider } from 'react-redux';
import store from './store';

const languages = ['es'];
const languageId = window.Liferay ? window.Liferay.ThemeDisplay.getLanguageId().split('_')[0] : Cookies.get('GUEST_LANGUAGE_ID ') ? Cookies.get('GUEST_LANGUAGE_ID ').split('_')[0] : undefined;
const isSignedIn = window.Liferay ? window.Liferay.ThemeDisplay.isSignedIn() : false;
const userId = window.Liferay ? window.Liferay.ThemeDisplay.getUserId() : '';
const userEmail = window.Liferay ? window.Liferay.ThemeDisplay.getUserEmailAddress() : '';

const selectedLang = languageId || (navigator.language || navigator.userLanguage).split('-')[0];
const lng = 'es';

i18next.init({
  interpolation: { escapeValue: false },
  lng: lng,
  resources: {
    es: {
      global: global_es
    }
  }
})

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App
        lng={lng}
        isSignedIn={isSignedIn}
        userId={userId}
        userEmail={userEmail}
      />
    </I18nextProvider>
  </Provider>
  ,
  document.getElementById('gpt-app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
