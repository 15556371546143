import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './ChatFooter.module.css';
import { ReactComponent as SendImage } from '../../../assets/images/send-icon.svg';

const ChatFooter = (props) => {
  const { t } = useTranslation('global');
  const textInput = useRef(null);
  const inputDisabled = useSelector(state => state.chat.inputDisabled);

  const submitHandler = () => {
    const inputText = textInput.current.value;
    textInput.current.value = '';
    props.send(inputText);
  };

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      submitHandler();
    }
  };
  return (

    <div className={classes.ChatFooter}>
      <input
        type="text"
        placeholder={t('chatfooter.input.placeholder')}
        onKeyPress={handleKeypress}
        disabled={inputDisabled}
        ref={textInput} />
      <div className={classes.SendButton} onClick={submitHandler}>
        <SendImage />
      </div>
    </div>

  );
}
export default ChatFooter;