// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextMessage_TextMessage__3Etvd{\r\n  width: auto;\r\n  box-sizing: border-box;\r\n  border-radius: 16px;\r\n  max-width: 350px;\r\n  min-width: 0px;\r\n  min-height: 26px;\r\n  padding: 5px 8px;\r\n  grid-gap: 10px;\r\n  gap: 10px;\r\n}\r\n\r\n.TextMessage_TextMessage__3Etvd p{\r\n  margin: 0;\r\n  color: #000;\r\n}\r\n\r\n.TextMessage_Bot-message__19AY4{\r\n  background-color:#F5F5F5;\r\n}\r\n.TextMessage_User-message__2aNkQ{\r\n  background-color:#105FAF;\r\n  align-self: flex-end;\r\n  color: #FFFFFF;\r\n  padding: 4px 12px;\r\n}", "",{"version":3,"sources":["webpack://src/components/chatElements/TextMessage/TextMessage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,cAAS;EAAT,SAAS;AACX;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".TextMessage{\r\n  width: auto;\r\n  box-sizing: border-box;\r\n  border-radius: 16px;\r\n  max-width: 350px;\r\n  min-width: 0px;\r\n  min-height: 26px;\r\n  padding: 5px 8px;\r\n  gap: 10px;\r\n}\r\n\r\n.TextMessage p{\r\n  margin: 0;\r\n  color: #000;\r\n}\r\n\r\n.Bot-message{\r\n  background-color:#F5F5F5;\r\n}\r\n.User-message{\r\n  background-color:#105FAF;\r\n  align-self: flex-end;\r\n  color: #FFFFFF;\r\n  padding: 4px 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextMessage": "TextMessage_TextMessage__3Etvd",
	"Bot-message": "TextMessage_Bot-message__19AY4",
	"User-message": "TextMessage_User-message__2aNkQ"
};
export default ___CSS_LOADER_EXPORT___;
