import axios from 'axios';
import processResponse from '../processResponse';
import config from './config';


class EvaMsg {
  constructor({ text, code, context }) {
    this.text = text;
    this.code = code;
    this.context = context;
  }
}

const createSessionCode = () => {
  return new Promise((resolve) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let sessionId = '';

    for (let i = 0; i < 10; i++) {
      sessionId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    resolve(sessionId);
  });
}

export const startConversation = async ({ lng, isSignedIn, userId, userEmail }) => {
  let sessionId = await createSessionCode();
  console.log('SESSION_ID: ', sessionId);
  return new Promise((resolve) => {
    const { messages, sessionCode, context, inputDisabled } = processResponse({
      data: {
        context: '',
        session_id: sessionId,
        text: 'Hola, soy el asistente de SEAT. Puedo ayudarte a encontrar información o documentación que estés buscando. Actualmente puedo ayudarte a encontrar actas y a generar fórmulas de Excel. ¿Qué necesitas?'

      }
    });
    sessionStorage.setItem('SESSION', sessionCode);
    console.log('sessionCode: ', sessionCode);
    sessionStorage.setItem('CONTEXT', context);
    return resolve({ messages, inputDisabled });
  })
}

export const sendMessage = async ({ text, code, options }) => {

  let sessionId = sessionStorage.getItem('SESSION');
  const contextStorage = sessionStorage.getItem('CONTEXT');
  if (!sessionId || sessionId === 'undefined') {
    sessionId = await createSessionCode();
    console.log('SESSION_ID: ', sessionId);
    sessionStorage.setItem('SESSION', sessionId);
  }

  return new Promise((resolve, reject) => {
    const context = contextStorage || '';
    const msg = new EvaMsg({
      text: text || '',
      code: code || '',
      context: context
    });
    const headers = config.conversation.headers;
    headers["session_id"] = sessionId;

    axios.post(`${config.conversation.url.instance}/${config.conversation.url.endpoint}`, msg, { headers: headers })
      .then(response => {
        const { messages, sessionCode, context, inputDisabled } = processResponse(response);
        sessionStorage.setItem('SESSION', sessionCode);
        sessionStorage.setItem('CONTEXT', context);
        return resolve({ messages, inputDisabled });
      })
      .catch(err => {
        // TODO capturar error sesión?
        reject(err);
      });
  });
}